/* You can add global styles to this file, and also import other style files */
@import "primeicons/primeicons.css";

:root {
  --vini-primary: #dc001c;
  --vini-primary-dark: #A30016;
  --vini-primary-medium: #BD0019;
  --vini-primary-light: #F50021;

  --vini-secondary: #111314;
  --vini-secondary-dark: #232629;
  --vini-secondary-medium: #3B4045;
  --vini-secondary-light: #5C646B;

  --black: #000;

  --safe-area-bottom: env(safe-area-inset-bottom);
}
html, body {
  overscroll-behavior: contain;
}

body{
    background-color: rgb(222, 222, 222);
    font-size: 14px;
    color: #111314
}

.bg-vini-primary {
  background-color: var(--vini-primary) !important;
}

.bg-vini-primary-dark {
  background-color: var(--vini-primary-dark) !important;
}

.bg-vini-primary-medium {
  background-color: var(--vini-primary-medium) !important;
}

.bg-vini-primary-light {
  background-color: var(--vini-primary-light) !important;
}

.bg-vini-secondary {
  background-color: var(--vini-secondary) !important;
}

.bg-vini-secondary-dark {
  background-color: var(--vini-secondary-dark) !important;
}

.bg-vini-secondary-medium {
  background-color: var(--vini-secondary-medium) !important;
}

.bg-vini-secondary-light {
  background-color: var(--vini-secondary-light) !important;
}

.text-vini-primary, .text-vini-primary span {
  color: var(--vini-primary) !important;
}

.text-vini-primary-dark {
  color: var(--vini-primary-dark) !important;
}

.text-vini-primary-medium {
  color: var(--vini-primary-medium) !important;
}

.text-vini-primary-light {
  color: var(--vini-primary-light) !important;
}

.btn-vini-primary, .btn-vini-primary .p-button {
  background-color: var(--vini-primary) !important;
  color: #fff !important;
}

.btn-vini-primary:hover, .btn-vini-primary:hover .p-button {
  background-color: var(--vini-primary-dark) !important;
  color: #fff !important;
}

.btn-vini-secondary-light {
  background-color: var(--vini-secondary-light) !important;
  color: #fff !important;
}

.btn-vini-secondary-light:hover, .btn-vini-secondary-light:hover .p-button {
  background-color: var(--vini-secondary-medium) !important;
  color: #fff !important;
}

.pointer {
  cursor: pointer;
}

h1 {
  font-size: 32px;
  color: var(--vini-secondary-dark);
}

h2 {
  font-size: 26px;
  color: var(--black);
}

h3 {
  font-size: 18px;
  color: var(--vini-secondary-dark);
}

h1 {
  font-size: 16px;
  color: var(--vini-secondary-dark);
}
.fullscreen {
  width: 100dvw;
  height: 100dvh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}

.logo-vini {
  max-width: 50px;
  position: absolute;
  left: 40px;
  top: 15px;
}

#sidebar .logo-vini {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

ul {
    padding-left: 2rem;
    padding-right: 2rem;
}
.p-dropdown{
    width: 100%;
}
.p-calendar{
    width: 100%;
}
.p-stepper {
    flex-basis: 40rem;
}
input{
    height: 50px;
}

#netheosIframe {
  height: 98dvh;
  overflow-y: auto;
}

#netheosIframe.hidden {
  position: absolute;
  opacity: 0;
  left: 2000px;
  height: 0;
}


iframe {
  pointer-events: auto; /* Permet l'interaction avec l'iframe */
}

#otpForm {
  // position: absolute;
  // z-index: 9;
  // width: 100dvw;
  // height: 100dvh;
  // top: 0px;
  // left: 0px;
}

.custom-otp-input {
    width: 48px;
    height: 48px;
    font-size: 24px;
    appearance: none;
    text-align: center;
    transition: all 0.2s;
    border-radius: 0;
    border: 1px solid var(--surface-400);
    background: #fff;
    outline-offset: -2px;
    outline-color: transparent;
    border-right: 0 none;
    transition: outline-color 0.3s;
    color: var(--text-color);
}

.custom-otp-input:focus {
    outline: 2px solid var(--primary-color);
}

.custom-otp-input:first-child,
.custom-otp-input:nth-child(5) {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.custom-otp-input:nth-child(3),
.custom-otp-input:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-right-width: 1px;
    border-right-style: solid;
    border-color: var(--surface-400);
}

.error-image {
  width: 100%;
  max-width: 200px;
}

.flag-image {
  max-width: 50px;
  margin: 0 15px 0 0;
}

.with-validate-btn {
  padding-bottom: 50px;
}

//LOADER

/* Loader styles */
.loaderWrapper {
  position: absolute;
  width: 100dvw;
  height: calc(100% - 43px);
  background: #ededf0;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid var(--vini-primary);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/**/

.animation-container {
  transition: all 2s ease-in-out;
}
// ANIMATIONS

.slideInLeftAnimation {
  opacity: 0; /* Initialement invisible */
  transform: translateX(-100%); /* Position en dehors de l'écran au-dessus */
  animation: slideInLeft 0.5s ease-in-out forwards 1s;
  animation-delay: 1s;
}

@keyframes slideInLeft {
  to {
    opacity: 1;
    transform: translateX(0); /* Apporter l'élément à sa position initiale */
  }
}

.slideInRightAnimation {
  opacity: 0; /* Initialement invisible */
  transform: translateX(100%); /* Position en dehors de l'écran au-dessus */
  animation: slideInRight 0.5s ease-out forwards;
  animation-delay: 1s;
}

@keyframes slideInRight {
  to {
    opacity: 1;
    transform: translateX(0); /* Apporter l'élément à sa position initiale */
  }
}

.slideInTopAnimation {
  opacity: 0; /* Initialement invisible */
  transform: translateY(-100%); /* Position en dehors de l'écran au-dessus */
  animation: slideInTop 0.5s ease-out forwards;
  animation-delay: 1s;
}

@keyframes slideInTop {
  to {
    opacity: 1;
    transform: translateY(0); /* Apporter l'élément à sa position initiale */
  }
}

.slideInBottomAnimation {
  opacity: 0; /* Initialement invisible */
  transform: translateY(100%); /* Position en dehors de l'écran au-dessus */
  animation: slideInBottom 0.5s ease-out forwards;
  animation-delay: 1s;
}

@keyframes slideInBottom {
  to {
    opacity: 1;
    transform: translateY(0); /* Apporter l'élément à sa position initiale */
  }
}

//

header {
  animation: bigExpandHeight 1s ease-in-out forwards;
  -moz-border-radius: 100px / 50px;
  -webkit-border-radius: 100px / 50px;
  border-radius: 25% 25% 50% 50% / 0% 0% 25% 25%;
  height: 100%;
  width: 110%;
  position: absolute;
  content: "";
  top: -5%;
  left: -5%;
  background-color: var(--vini-primary);
}

header.header-jeu{
  position: relative;
  top: -5%;
  left: -5%;
  background-color: #F6F5F9;
  animation: none;
  -moz-border-radius: 100px / 50px;
  -webkit-border-radius: 100px / 50px;
  border-radius: 25% 25% 50% 50% / 0% 0% 25% 25%;
  background-color: var(--vini-primary);
  padding-bottom: 65px !important;
}

header.revendeur{
  height: 120px;
  top: 0px;
  animation: miniExpandHeight 1s ease-in-out forwards;
  z-index: 1;
}
app-identification header{
  height: 120px;
  top: 0px;
  animation: miniExpandHeight 1s ease-in-out forwards;
  z-index: 1;
}

@keyframes bigExpandHeight {
  0% {
    height: 100dvh;
  }
  100% {
    height: 60dvh;
  }
}

@keyframes miniExpandHeight {
  0% {
    height: 60dvh;
  }
  100% {
    height: 120px;
  }
}

.content {
  height: 40dvh;
  width: 100dvw;
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
}

footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100dvw;
  z-index: 99;
}

.icon-header {
  width: 100%;
  max-width: 140px;
}


.steppers {
  width: 94dvw;
  height: 59px;
  position: absolute;
  top: 80px;
  background: #fff;
  padding: 0 12px;
  max-width: 800px;
}

.steppers .slider {
  position: absolute;
  background-color: var(--vini-primary);
  height: 40px;
  top: 10px;
  left: 12px;
  width: 110px;
  border-radius: var(--bs-border-radius-pill) !important;
  transition: left 0.3s ease-in-out, width 0.3s ease-in-out;
}

.revendeur .steppers .slider {
  width: 96.99%;
}

.stepper {
  float: left;
  font-size: 14px;
  color: #6a6a6a;
  font-weight: bold;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  width: 33.33%;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.revendeur .stepper.active {
  width: 99.99%;
}

.stepper.active {
  float: left;
  font-size: 14px;
  color: #ffffff;
}

.identificationContent {
  height: 100dvh;
  width: 100dvw;
  position: absolute;
  content: "";
  top: 0;
  padding: 160px 0 0 0;
  left: 50%;
  overflow-y: auto;
  max-width: 800px;
  transform: translateX(-50%);
  z-index: 9;
}
.identificationContent.showNetheosIframeOpened {
  padding: 0 !important;
  max-width: 100dvw;
  position: unset;
  transform: unset;
  // margin: -10px 0 50px 0;
  overflow-y: scroll;
}

.mandatory {
  color: red;
}

.is-invalid {
  border: 1px solid red !important;
  border-radius: var(--bs-border-radius);
}

.is-invalid .p-dropdown,
.is-invalid .p-calendar {
  border: 1px solid red !important;
  border-radius: var(--bs-border-radius);
}

.custom-checkbox .p-checkbox-box {
  border-color: var(--vini-primary) !important; /* Bordure rouge */
}

.custom-checkbox .p-checkbox-box.p-highlight {
  background-color: var(--vini-primary) !important; /* Arrière-plan rouge quand sélectionné */
  border-color: var(--vini-primary) !important;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
}

#netheosIframe .close {
  position: unset;
  float: right;
  margin: 5px 10px 5px 0;

}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ececec;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* NOTIFICATION */
.overlay {
  position: fixed; /* Pour couvrir toute la page */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Couleur noire avec 50% de transparence */
  backdrop-filter: blur(10px); /* Applique un flou de 10px */
  display: flex;
  justify-content: center; /* Centre le contenu horizontalement */
  align-items: center; /* Centre le contenu verticalement */
  z-index: 9999; /* Assure que l'overlay est au-dessus des autres éléments */
}

.overlay-content {
  padding: 20px;
}

#languageComponent h2 {
  color: #fff;
}


.jeuContent {
  width: 100dvw;
  max-width: 800px;
}

.conditions {
  font-size: 10px;
  font-style: italic;
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
